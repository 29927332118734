<template>
  <div>
    <SharedDialog class="dialog">
      <template #title>
        <p class="dialog__title mb-4">{{ messages.lessonMemos }}</p>
      </template>
      <template #content>
        <div>
          <div v-for="file in lesson.lesson_files" :key="`file-${file.id}`">
            <BaseFileItem
              :name="`${file.file_name}`"
              :url="file.file_url"
              :type="file.type"
              @delete="deleteFile(file.id)"
            />
          </div>
          <div class="media__controls d-flex">
            <v-file-input
              id="upload-file"
              ref="file"
              :key="key"
              prepend-icon=""
              flat
              dense
              outlined
              hide-details
              persistent-placeholder
              truncate-length="40"
              :accept="mediaAccept"
              :hide-input="false"
              :placeholder="messages.uploadMaterials"
              class="file-input"
              @change="updateFile($event)"
            />
          </div>
        </div>
        <v-divider class="my-6"></v-divider>
        <div>
          <p class="dialog__description">
            {{ messages.teacherMemos }}
          </p>
          <Loading v-if="loading" class="mt-10 mb-10"></Loading>
          <textarea
            v-else-if="role === 'teacher'"
            v-model="lesson.teacher_memos"
            maxlength="255"
            solo
            auto-grow
            class="dialog__text-area mb-2"
          />
          <span v-else>{{ lesson.teacher_memos || "-" }}</span>
        </div>
        <div v-if="!groupLessons">
          <p class="dialog__description">
            {{ messages.studentMemos }}
          </p>
          <Loading v-if="loading" class="mt-10 mb-10"></Loading>
          <textarea
            v-else-if="role === 'student'"
            v-model="lesson.student_memos"
            maxlength="255"
            solo
            auto-grow
            class="dialog__text-area mb-2"
          />
          <span v-else>{{ lesson.student_memos || "-" }}</span>
        </div>
      </template>
      <template #actions="{ closeDialog }">
        <BaseButton :disabled="loading" class="confirm-button" @click="closeDialog(editMemos)">{{
          messages.editMemos
        }}</BaseButton></template
      >
    </SharedDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import notyMixin from "@/mixins/notyMixin"
import spinnerMixin from "@/mixins/spinnerMixin"
import keyMixin from "@/mixins/keyMixin"

export default {
  mixins: [notyMixin, spinnerMixin, keyMixin],
  data() {
    return {
      lesson: {},
      role: "",
      mediaAccept:
        "application/wav,audio/wav,audio/x-wav,video/mp4,application/pdf," +
        "image/jpeg,image/jpg,image/png,image/heic,image/heif,image/svg+xml,image/webp," +
        ".docx,.doc,.xlsx,.xls,.pptx,.ppt,.csv",
      groupLessons: false,
    }
  },
  computed: {
    messages() {
      return {
        lessonMemos: this.$t("message.lessonMemos"),
        teacherMemos: this.$t("message.teacherMemos"),
        studentMemos: this.$t("message.studentMemos"),
        editMemos: this.$t("message.saveChanges"),
        uploadMaterials: this.$t("message.uploadMaterials"),
        file: this.$t("message.file"),
      }
    },
    ...mapGetters("layout", {
      dialogData: "getDialogConfig",
    }),
  },
  created() {
    const { lesson, role, groupLessons } = this.dialogData?.data
    this.lesson = lesson
    this.role = role
    this.groupLessons = groupLessons
  },
  methods: {
    ...mapActions("lessons", {
      updateLesson: "updateLesson",
      updateLessonMaterials: "updateLessonMaterials",
      removeFileFromLessonMaterials: "removeFileFromLessonMaterials",
    }),
    async editMemos() {
      const data = {
        teacher_memos: this.lesson.teacher_memos,
        student_memos: this.lesson.student_memos,
        lesson_id: this.lesson.id,
      }
      try {
        this.isLoading()
        await this.updateLesson({ data, updateTable: this.whichTableToUpdate() })
        this.simpleSuccess({ text: this.$t("message.success.addNote") })
      } catch (err) {
        this.simpleError({ text: this.$t("message.error.updateData") })
      } finally {
        this.isLoaded()
      }
    },
    whichTableToUpdate() {
      let updateTable = this.lesson.finished_at ? "setClosedLessons" : "setScheduledLessons"
      if (this.groupLessons && this.role === "student") updateTable = "getGroupLessons"
      return updateTable
    },
    async updateFile(file) {
      if (!file) return
      const oversize = file?.size > 10 * 1024 * 1024
      if (oversize) {
        this.simpleError({
          text: `${this.$t("message.error.imageSize")}  ${this.$t("message.error.materialSize")}`,
        })
        this.resetFileInputValue()
        return
      }

      try {
        this.isLoading()
        const res = await this.updateLessonMaterials({
          data: {
            lesson_id: this.lesson.id,
            lesson_note_file: file,
          },
          updateTable: this.whichTableToUpdate(),
        })
        this.lesson = res.data
      } catch (_) {
        this.simpleError({ text: this.$t("message.error.updateData") })
        return Promise.reject(`failed to update lesson materials`)
      } finally {
        this.keyPlus()
        this.isLoaded()
      }
    },
    simpleError({ text = this.$t("notifications.dataUpdateError") } = {}, timeout = 1500) {
      this.$noty.error(text, {
        timeout,
      })
      return false
    },
    resetFileInputValue() {
      this.$nextTick(function () {
        const [ref] = this.$refs.file
        if (ref) {
          ref.reset()
        }
      })
    },
    async deleteFile(id) {
      try {
        this.isLoading()
        const res = await this.removeFileFromLessonMaterials({
          data: {
            lesson_id: this.lesson.id,
            file_id: id,
          },
          updateTable: this.whichTableToUpdate(),
        })
        this.lesson = res.data
      } catch (_) {
        this.simpleError({ text: this.$t("message.error.updateData") })
        return Promise.reject(`failed to remove file from lesson materials`)
      } finally {
        this.keyPlus()
        this.isLoaded()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog {
  &__title {
    font-weight: bold;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
  }

  &__text-area {
    width: 100%;
    height: 94px;
    background-color: #fafbff !important;
    border: 1px solid #eceff9;
    border-radius: 0.5rem;
    resize: none;
    padding: 0.4rem 0.6rem;

    &:focus {
      outline: none;
    }
  }
  &__description {
    font-size: 0.875rem;
    margin: 0.5rem 0 1rem 0 !important;
  }
}

::v-deep.v-input.file-input {
  .v-input__append-inner .v-input__icon button.v-icon {
    margin-right: 12px !important;
  }
}
</style>
